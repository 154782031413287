<script lang="ts" setup>
const enableAnimations = defineModel<boolean>({ required: true });
const emit = defineEmits<{ start: []; scrollToGetStarted: [] }>();

const route = useRoute();
const { isLoggedIn, isGhostStudent } = useAuth();

const showLoginButton = computed(() => {
  return !isLoggedIn.value || isGhostStudent.value;
});

const showScroll = computed(() => {
  return route.name !== 'plan-onboarding';
});
</script>

<template>
  <div class="flex flex-col items-center justify-center gap-8" data-testid="onboarding-landed">
    <div class="relative">
      <div v-if="!isLoggedIn" class="relative ml-auto w-fit rounded bg-teal-500 p-1 text-sm font-semibold md:absolute md:-right-8 md:-top-4">
        {{ $t('general.free').toUpperCase() }}
      </div>
      <UIHeading :htmlText="$t('activities.onboarding.selfDiscovery')" class="text-center text-white" size="h1" styleSize="h2" />
    </div>
    <p class="max-w-[370px] text-center text-white">{{ $t('activities.onboarding.onboardingIntro') }}</p>
    <slot name="cta">
      <UIButton :text="$t('activities.onboarding.startYourJourney')" data-testid="next-action" @click="emit('start')" />
      <UIButton
        v-if="showLoginButton"
        :text="$t('activities.onboarding.alreadyHaveAnAccount')"
        class="text-white underline"
        to="/login"
        variant="no-style"
      />
    </slot>
    <button v-if="showScroll" class="absolute bottom-3 flex flex-col" :aria-label="$t('general.scrollDown')" @click="emit('scrollToGetStarted')">
      <UIAssetIcon class="translate-y-4 text-white/60" path="chevron-down" size="lg" />
      <UIAssetIcon class="text-white" path="chevron-down" size="lg" />
    </button>
    <UISwitch
      v-model="enableAnimations"
      class="bottom-3 right-3 block text-base lg:absolute"
      :labelLeft="$t('general.animationSwitch.off')"
      :labelRight="$t('general.animationSwitch.on')"
      theme="dark"
    />
  </div>
</template>
